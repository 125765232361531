<template>
  <div class="tc-online_video">
    <!-- 头部 -->
    <div class="video_header">
      <p>
        2020年5月9日 （星期六） --- 视频问诊已经进行：7 分 35 秒
        <el-divider direction="vertical"></el-divider>
        <span>
          当前房间号：{{roomId}}
          <el-divider direction="vertical"></el-divider>
          当前视频状态：{{roomStatus}}
          <el-divider direction="vertical"></el-divider>
          <!-- {{selectedStreamStatus}} -->
          患者昵称：
          <span v-if="userName!=''">{{userName}}</span>
          <span v-else>暂无</span>
        </span>
        <span style="float:right;padding-right: 20px">
          <el-button type="primary" v-on:click="handlePublish" size="small">开启视频</el-button>
          <el-divider direction="vertical"></el-divider>
          <el-button type="primary" v-on:click="handleLeaveRoom" size="small">关闭视频</el-button>
          <!-- <button v-on:click="handlePublish">发布</button>
          <button v-on:click="handlePublishScreen">屏幕共享</button>
          <button v-on:click="handleUnpublish">取消发布/屏幕共享</button>
          <button v-on:click="handleSubscribe">订阅</button>
          <button v-on:click="handleUnsubscribe">取消订阅</button>-->
          <!-- <button v-on:click="handleLeaveRoom">离开房间</button> -->
        </span>
      </p>
    </div>
    <!-- 视频 -->
    <div class="video">
      <div class="row1">
        <!-- 视频窗口 -->
        <div class="col1">
          <!-- 大视频 -->
          <div class="videoWindow">
            <div
              class="stream-container"
              v-for="stream in remoteStreams"
              :key="stream.sid"
              v-on:click="handleSelectStream(stream)"
            >
              <stream-info v-bind:client="client" v-bind:stream="stream"></stream-info>
              <div class="video-container" :id="stream.sid"></div>
            </div>

            <div
              class="stream-container viceVideoWindow"
              v-for="stream in localStreams"
              :key="stream.sid"
              v-on:click="handleSelectStream(stream)"
            >
              <stream-info v-bind:client="client" v-bind:stream="stream"></stream-info>
              <div class="video-container" :id="stream.sid"></div>
            </div>
          </div>
          <div class="videoSet">
            <div class="left">
              <!-- 麦克风 -->
              <!-- <div class="microphone">
                <i class="fa fa-microphone"></i>
                <div class="range">
                  <input type="range" value="50" />
                </div>
              </div>-->
              <!-- 声音 -->
              <!-- <div class="voice">
                <i class="fa fa-volume-up"></i>
                <div class="range">
                  <input type="range" value="50" />
                </div>
              </div>-->
            </div>
            <div class="right">
              <div class="full">
                <i class="fa fa-expand"></i>
              </div>
            </div>
          </div>
        </div>
        <!-- 聊天窗口 -->
        <div class="col2">
          <!-- 顶部 -->
          <div class="col2-1">
            <p>在线问诊</p>
          </div>
          <!-- 内容区域 -->
          <div class="col2-2">
            <!-- 医生 -->
            <div class="doctorMsg">
              <div class="doctorInfo">
                <div class="img">
                  <img src="../../../assets/images/11da1418f787ccf9c11dbedb43ebe41f.jpeg" alt />
                </div>
                <div class="info">
                  <div class="name">张医生</div>
                  <div class="time">12:32:36</div>
                </div>
              </div>
              <div class="msg">您好，请问是唐加宇先生吗？</div>
            </div>
            <!-- 病人 -->
            <div class="patientMsg">
              <div class="patientInfo">
                <div class="info">
                  <div class="name">唐加宇</div>
                  <div class="time">12:32:36</div>
                </div>
                <div class="img">
                  <img src="../../../assets/images/txbb.jpg" alt />
                </div>
              </div>
              <div class="msg">恩，是的!</div>
            </div>
          </div>
          <!-- 底部-->
          <div class="col2-3">
            <el-input v-model="msg" placeholder="请输入内容"></el-input>
            <el-button type="primary">发送</el-button>
          </div>
        </div>
      </div>
      <div class="row2">
        <el-input type="textarea" :rows="5" placeholder="患者批注" v-model="textarea"></el-input>
      </div>
    </div>
    <!-- 设置房间号 -->
    <!-- <el-dialog title="设置房间号" :visible.sync="setRoomBox" width="20%" center>
      <el-input v-model="inputRommId" placeholder="请输入内容"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setRoomBox = false">取 消</el-button>
        <el-button type="primary" @click="handleSetRoom">确 定</el-button>
      </span>
    </el-dialog>-->
  </div>
</template>
<script>
import sdk, { Client } from "urtc-sdk";
import { ajoinvdeo, alevavideo } from "@/api/video.js";
const AppId = "urtc-4hwfhylz";
const AppKey = "8eb0870aa5762951bfaff22f262b1bd9";
// 此处使用固定的房间号的随机的用户ID，请自行替换
const RoomId = "001";
const UserId = localStorage.getItem("adminName");
export default {
  data() {
    return {
      inputRommId: RoomId,
      setRoomBox: false,
      msg: "",
      roomId: RoomId,
      userId: UserId,
      isJoinedRoom: false,
      selectedStream: null,
      localStreams: [],
      remoteStreams: [],
      userName: "",
      textarea: ""
    };
  },
  // 计算属性
  computed: {
    // 是否已加入
    roomStatus: function() {
      return this.isJoinedRoom ? "已开启" : "未开启";
    },
    // 是否选择
    selectedStreamStatus: function() {
      return this.selectedStream ? this.selectedStream.sid : "未选择";
    }
  },
  // 渲染完成前调用
  created: function() {
    // 判断是否存在 appid 和 AppKey
    if (!AppId || !AppKey) {
      alert("请先设置 AppId 和 AppKey");
      return;
    }
    // 判断是否存在 房间号 和 用户id
    if (!RoomId) {
      alert("请先设置 RoomId");
      return;
    }
    if (!UserId) {
      alert("请先设置 UserId");
    }
  },
  // 渲染完成后调用
  mounted: function() {
    // 创建 token
    const token = sdk.generateToken(AppId, AppKey, RoomId, UserId);
    this.client = new Client(AppId, token);
    //监听-本地流已发布，可在页面中播放该流
    this.client.on("stream-published", localStream => {
      this.selectedStream = localStream;
      console.info("stream-published: ", localStream);
      const { localStreams } = this;
      localStreams.push(localStream);
      this.$nextTick(() => {
        this.client.play({
          streamId: localStream.sid,
          container: localStream.sid
        });
      });
    });
    // 监听-有其他用户加入房间，自动订阅
    this.client.on("stream-added", remoteStream => {
      console.info("stream-added: ", remoteStream);
      const { remoteStreams } = this;
      this.userName = remoteStream.uid;
      remoteStreams.push(remoteStream);
      // 自动订阅
      this.client.subscribe(remoteStream.sid, err => {
        console.error("自动订阅失败：", err);
      });
    });
    // 监听-远端流已订阅，可在页面中播放该流
    this.client.on("stream-subscribed", remoteStream => {
      console.info("stream-subscribed: ", remoteStream);
      const { remoteStreams } = this;
      const idx = remoteStreams.findIndex(
        item => item.sid === remoteStream.sid
      );
      if (idx >= 0) {
        remoteStreams.splice(idx, 1, remoteStream);
      }
      this.$nextTick(() => {
        this.client.play({
          streamId: remoteStream.sid,
          container: remoteStream.sid
        });
      });
    });
    // 监听-有其他用户取消发布了一条流
    this.client.on("stream-removed", remoteStream => {
      console.info("stream-removed: ", remoteStream);
      const { remoteStreams } = this;
      const idx = remoteStreams.findIndex(
        item => item.sid === remoteStream.sid
      );
      if (idx >= 0) {
        remoteStreams.splice(idx, 1);
      }
    });
    // 监听-断线重连
    this.client.on("connection-state-change", ({ previous, current }) => {
      //console.log(`连接状态 ${previous} -> ${current}`);
    });
    // 监听- 发布/订阅流断开时，会自动重连，发布流会被重新发布，订阅流会被重新订阅，完成后会触发此事件
    this.client.on("stream-reconnected", ({ previous, current }) => {
      //console.log(`流已断开重连`);
      const isLocalStream = previous.type === "publish";
      const streams = isLocalStream ? this.localStreams : this.remoteStreams;
      const idx = streams.findIndex(item => item.sid === previous.sid);
      if (idx >= 0) {
        // 更新流的信息
        streams.splice(idx, 1, current);
      }
      this.$nextTick(() => {
        this.client.play({
          streamId: current.sid,
          container: current.sid
        });
      });
    });
    // 当浏览器窗口关闭或者刷新时,会触发beforeunload事件
    window.addEventListener("beforeunload", this.handleLeaveRoom);
  },
  // 实例销毁之前调用
  beforeDestroy: function() {
    console.info("component will destroy");
    window.removeEventListener("beforeunload", this.handleLeaveRoom);
    this.handleLeaveRoom();
    this.adminleave();
  },
  // 离开页面的时候调用
  destroyed: function() {
    this.isComponentDestroyed = true;
  },
  // 方法域
  methods: {
    // 点击加入房间
    handleJoinRoom: function() {
      const { roomId, userId, isJoinedRoom } = this;
      if (isJoinedRoom) {
        alert("已经加入了房间");
        return;
      }
      if (!roomId) {
        alert("请先填写房间号");
        return;
      }

      this.client.joinRoom(
        roomId,
        userId,
        () => {
          this.isJoinedRoom = true;
          this.adminjoin();
          console.info("加入房间成功");
          this.client.publish({
            audio: true,
            video: true
          });
        },
        err => {
          console.error("加入房间失败： ", err);
        }
      );
    },
    // 点击发布
    handlePublish: function() {
      this.handleJoinRoom();
      // this.client.publish(err => {
      //   console.error(
      //     `发布失败：错误码 - ${err.name}，错误信息 - ${err.message}`
      //   );
      // });
    },
    // 屏幕共享
    handlePublishScreen: function() {
      this.client.publish({ audio: false, video: false, screen: true }, err => {
        console.error(
          `发布失败：错误码 - ${err.name}，错误信息 - ${err.message}`
        );
      });
    },
    // 取消发布本地流
    handleUnpublish: function() {
      const { selectedStream } = this;
      //console.log(this.client.getMediaStream(UserId));
      if (!selectedStream) {
        alert("还没有开启视频");
        return;
      }
      this.client.unpublish(
        selectedStream.sid,
        stream => {
          console.info("取消发布本地流成功：", stream);
          this.isJoinedRoom = false;
          const { localStreams } = this;
          const idx = localStreams.findIndex(item => item.sid === stream.sid);
          if (idx >= 0) {
            localStreams.splice(idx, 1);
          }
          this.selectedStream = null;
        },
        err => {
          console.error("取消发布本地流失败：", err);
        }
      );
    },
    // 订阅远端流
    handleSubscribe: function() {
      const { selectedStream } = this;
      if (!selectedStream) {
        alert("未选择需要订阅的远端流");
        return;
      }
      this.client.subscribe(selectedStream.sid, err => {
        console.error("订阅失败：", err);
      });
    },
    // 取消订阅远端流
    handleUnsubscribe: function() {
      const { selectedStream } = this;
      if (!selectedStream) {
        alert("未选择需要取消订阅的远端流");
        return;
      }
      this.client.unsubscribe(
        selectedStream.sid,
        stream => {
          console.info("取消订阅成功：", stream);
          const { remoteStreams } = this;
          const idx = remoteStreams.findIndex(item => item.sid === stream.sid);
          if (idx >= 0) {
            remoteStreams.splice(idx, 1, stream);
          }
        },
        err => {
          console.error("订阅失败：", err);
        }
      );
    },
    // 离开房间
    handleLeaveRoom: function() {
      const { isJoinedRoom } = this;
      if (!isJoinedRoom) {
        return;
      }
      this.client.leaveRoom(
        () => {
          console.info("离开房间成功");
          this.adminleave();
          this.selectedStream = null;
          this.localStreams = [];
          this.remoteStreams = [];
          this.isJoinedRoom = false;
          this.userName = "";
        },
        err => {
          console.error("离开房间失败：", err);
        }
      );
    },
    // 当前流信息
    handleSelectStream: function(stream) {
      //console.log("select stream: ", stream);
      this.selectedStream = stream;
    },
    // 管理员加入
    adminjoin() {
      ajoinvdeo().then(res => {
        console.log(res);
      });
    },
    // 管理员离开
    adminleave() {
      alevavideo().then(res => {
        console.log(res);
      });
    }
  }
};
</script>
<style lang="less">
.tc-online_video {
  width: 100%;
  height: calc(~"100% - 126px");
  padding-bottom: 10px;
  // 头部
  .video_header {
    width: 100%;
    height: 50px;
    background: rgb(217, 242, 247);
    margin-top: 20px;
    p {
      color: rgb(65, 92, 72);
      line-height: 50px;
      padding-left: 20px;
    }
  }
  .video {
    margin-top: 20px;
    width: 100%;
    height: calc(~"100% - 20px");
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border: 1px #cccccc solid;
    padding: 5px;
    .row1 {
      flex: 6;
      margin-bottom: 10px;
      display: flex;
      .col1 {
        flex: 6;
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        .videoWindow {
          flex: 18;
          background: url("../../../assets/images/tmp1.jpg") no-repeat;
          background-size: 100% 100%;
          display: flex;
          position: relative;
          .viceVideoWindow {
            position: absolute;
            bottom: 20px;
            right: 20px;
            width: 150px;
            height: 150px;
            background: url("../../../assets/images/tmp2.jpg") no-repeat;
            background-size: 100% 100%;
            border: 3px lavender solid;
          }
        }
        .videoSet {
          flex: 1;
          background: rgb(127, 222, 243);
          padding: 5px 20px;
          display: flex;
          align-items: center;
          font-size: 28px;
          input[type="range"] {
            transform: rotate(-90deg);
            margin-top: 8px;
            outline: none;
            -webkit-appearance: none; /*清除系统默认样式*/
            width: 70px !important;
            background: -webkit-linear-gradient(#61bd12, #61bd12) no-repeat,
              #ddd;
            background-size: 50% 100%; /*设置左右宽度比例*/
            height: 5px; /*横条的高度*/
          }
          /*拖动块的样式*/
          input[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none; /*清除系统默认样式*/
            height: 16px; /*拖动块高度*/
            width: 16px; /*拖动块宽度*/
            background: #fff; /*拖动块背景*/
            border-radius: 50%; /*外观设置为圆形*/
            border: solid 1px #ddd; /*设置边框*/
          }
          .left {
            flex: 5;
            display: flex;
            .range {
              top: -64px;
              left: -25px;
              position: absolute;
            }
            .microphone {
              position: relative;
              margin-right: 30px;
            }
            .voice {
              position: relative;
            }
          }
          .right {
            flex: 1;
            display: flex;
            justify-content: flex-end;
          }
          i {
            color: rgb(242, 250, 255);
          }
        }
      }
      .col2 {
        flex: 2;
        display: flex;
        flex-direction: column;
        .col2-1 {
          flex: 2;
          font-size: 20px;
          display: flex;
          align-items: center;

          border-bottom: 1px solid #cccccc;
          p {
            padding-left: 10px;
            color: rgb(65, 92, 72);
            font-weight: 700;
            border-left: 3px solid rgb(130, 223, 242);
          }
        }
        .col2-2 {
          flex: 32;
          background: transparent;
          padding: 10px;
          .doctorMsg {
            .doctorInfo {
              display: flex;
              align-items: center;
              .img {
                width: 60px;
                border-radius: 50%;
                img {
                  width: 100%;
                  border-radius: 50%;
                }
              }
              .info {
                padding-left: 10px;
                text-align: center;
              }
            }
            .msg {
              display: inline-block;
              min-width: 60px;
              position: relative;
              margin-top: 10px;
              padding: 5px;
              border-radius: 5px;
              background: rgb(235, 208, 208);

              &::before {
                position: absolute;
                top: -10px;
                left: 20px;
                content: "";
                border: 10px solid rgb(235, 208, 208);
                border-right: 0px solid transparent;
                border-top: 0px solid transparent;
                border-right: 10px solid transparent;
                border-left: 10px solid transparent;
              }
            }
          }
          .patientMsg {
            .patientInfo {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              .img {
                width: 60px;
                border-radius: 50%;
                img {
                  width: 100%;
                  border-radius: 50%;
                }
              }
              .info {
                padding-right: 10px;
                text-align: center;
              }
            }
            .msg {
              position: relative;
              margin-top: 10px;
              padding: 5px;
              background: rgb(235, 208, 208);
              border-radius: 5px;
              display: flex;
              min-width: 60px;
              justify-content: flex-end;
              float: right;
              &::before {
                position: absolute;
                top: -10px;
                right: 20px;
                content: "";
                border: 10px solid rgb(235, 208, 208);
                border-right: 0px solid transparent;
                border-top: 0px solid transparent;
                border-right: 10px solid transparent;
                border-left: 10px solid transparent;
              }
            }
          }
        }
        .col2-3 {
          flex: 2.5;
          display: flex;
          align-items: center;
        }
      }
    }
    .row2 {
      flex: 1;
    }
  }
  .stream-container {
    display: inline-block;
    margin: 2px;
    width: 100%;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
  }
  .stream-container .video-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
}
</style>